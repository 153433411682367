<template>
  <div>
    <!-- MODALS -->
    <modal :adaptive="false" id="open_to_add_collection_name" name="open_to_add_collection_name"
      style="z-index:999999;">
      <AddCollectionModal ref="addCollectionModal" @onSubmit="addCollectionModalSubmit">
      </AddCollectionModal>
    </modal>
    <modal :adaptive="false" name="survey_list_modal">
      <EvaluationQuestionList :modal_name="'survey_list_modal'" :modal_title="survey_name"
        style="height:100% !important" :survey="item.evaluation_survey" @onSubmit="saveEvaluationSurvey">
      </EvaluationQuestionList>
    </modal>
    <modal :adaptive="false" name="rate_education_modal" style="width:500px;height: auto;">
      <Rating :modal_name="'rate_education_modal'" :survey="item.survey" @onSubmit="saveRating"></Rating>
    </modal>
    <!-- LOADING BOX -->

    <default-loading v-if="assignment_loading" minHeight="80vh"></default-loading>
    <!-- DESCRIPTION -->
    <div v-show="!assignment_loading" class="course-details-wrapper topic-1 uk-light pt-5">
      <div class="container p-sm-0">
        <div uk-grid>
          <div class="uk-width-2-3@m" style="margin-left: 27rem">
            <div class="course-details">
              <span>{{
                item.is_compulsory
                  ? $t("assignment.compulsory")
                  : $t("assignment.not_compulsory")
              }}</span>
              <h1>{{ item.name }}</h1>
              <div class="course-details-info mt-4">
                <ul>
                  <li>
                    <i class="icon-feather-clock"></i>
                    {{ $t("Süre") }}: {{ item.education_total_duration }}dk.
                  </li>
                </ul>
              </div>
              <div class="course-details-info ">
                <ul>
                  <li>
                    <i class="icon-feather-flag"></i>
                    {{ $t("education.Start_date") }}: {{ item.start_date }}
                  </li>
                  <li>
                    <i class="icon-feather-calendar"></i>
                    {{ $t("education.End_date") }}:{{ item.end_date }}
                  </li>
                </ul>
              </div>
              <div class="course-details-info">
                <ul>
                  <li>
                    <i class="icon-feather-users"></i>
                    {{ item.assigned_users_count }}
                    {{ $t("education.Person_Assigned_Training") }}
                  </li>
                  <li>
                    <i class="icon-feather-users"></i>
                    {{ item.completed_users_count }}
                    {{ $t("education.Person_Completed_Training") }}
                  </li>
                </ul>

                <div v-if="item.is_expired" class="bg-gradient-warning mt-3 uk-alert" uk-alert="">
                  <a class="uk-alert-close uk-icon uk-close" uk-close=""></a>
                  <p>
                    {{ $t("general.time_finished") }}
                  </p>
                </div>
              </div>
              <div class="course-details-info">
                <ul>
                  <li>
                    <div class="star-rating m-0 p-0 d-flex align-items-baseline">
                      <span class="avg"> {{ item.total_rating }} </span>
                      <star-rating :increment="star_increment" :max-rating="5" :padding="1" :rating="item.total_rating"
                        :read-only="true" :show-rating="false" :star-points="rating_star_points"
                        :star-size="20"></star-rating>
                    </div>
                  </li>
                </ul>
              </div>
              {{ this.$t('general.experience_sharing') }}
            </div>
            <nav class="responsive-tab style-5">
              <ul
                uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                <li>
                  <a href="javascript:void(0)">
                    <i class="uil-list-ul"></i>
                    {{ $t("education.descriptions") }}
                  </a>
                </li>
                <li v-if="item.has_feedbacks">
                  <a href="javascript:void(0)">
                    <i class="uil-comment-info-alt"></i>
                    {{ $t("education.Sharing_Experience") }}
                  </a>
                </li>
                <li v-if="item.has_present">
                  <a href="javascript:void(0)">
                    <i class="uil-trophy"></i>
                    {{ $t("education.Gifts_and_Bests") }}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <!-- MAIN CONTENT -->
    <div v-show="!assignment_loading" class="container">
      <div class="uk-grid-large mt-4" uk-grid>
        <!-- Main Content -->
        <div class="uk-width-2-3@m">
          <div v-if="successMessage != null" class="uk-alert-success position-relative" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p class="m-0" style="color: green">
              <i aria-hidden="true" class="fa fa-check d-inline-flex mr-2"></i>{{ successMessage }}
            </p>
          </div>
          <div v-if="errorMessage != null" class="uk-alert-danger bg-soft-danger position_relative" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p class="m-0" style="color: red">{{ errorMessage }}</p>
          </div>
          <ul id="course-intro-tab" class="uk-switcher mt-4">
            <!-- Course Contents -->
            <li>
              <h4>{{ $t("education.about") }}</h4>
              <p v-if="item.description && item.description !== 'null'" class="mb-4">
                {{ item.description }}
              </p>
              <div class="mb-4" uk-margin="">
                <a v-for="(hashtag, index) in item.hashtags" :key="'hashtag_' + index"
                  class="btn btn-small btn-light uk-first-column" href="javascript:void(0)">#{{ hashtag }}</a>
              </div>

              <div v-show="is_add_collection" class="uk-alert-primary2 p-4" uk-alert>
                <a class="uk-alert-close" @click="is_add_collection = false"></a>
                <h4>
                  <i class="uil-notebooks"></i>
                  {{ $t("education.Add_collection") }}
                </h4>
                <p>{{ $t("education.Add_collection_text") }}</p>
                <select v-model="collection_id" class="uk-select mb-2">
                  <option value="null">
                    {{ $t("education.Select_Collection") }}
                  </option>
                  <option value="0">[+] {{ $t("education.New_Collection") }}</option>
                  <template v-for="(item, index) in collections">
                    <option :selected="item.id === collection_id" :value="item.id">
                      {{ item.name }}
                    </option>
                  </template>
                </select>
                <button :disabled="collection_content_ids == null && collection_id != null"
                  class="btn btn-secondary btn-icon-label mt-2" type="button" @click.prevent="onsubmit">
                  <span class="btn-inner--icon">
                    <i class="uil-notebooks"></i>
                  </span>
                  <span class="btn-inner--text">{{ $t("general.save") }}</span>
                </button>
                <button class="btn btn-soft-secondary mt-2 ml-2" type="button" @click="is_add_collection = false">
                  {{ $t("general.give_up") }}
                </button>
              </div>

              <ul class="course-curriculum" uk-accordion="multiple: true">
                <li v-if="is_add_collection" class="uk-open">
                  <a class="uk-accordion-title" href="#">{{
                    $t("education.collection_subDiv")
                  }}</a>
                  <div class="uk-accordion-content">
                    <ul class="course-curriculum-list">
                      <li v-for="(educationitems,
                        education_index) in item.educations" :key="'add_collection_' + education_index" class="watch">
                        <input :id="'add_collection_checkbox_' + education_index" v-model="collection_content_ids"
                          :value="educationitems.id" class="d-inline-flex mb-0 mr-1" type="checkbox" />
                        <a>
                          <label :for="'add_collection_checkbox_' + education_index" class="small m-0 cursor-pointer">
                            {{ educationitems.title }}</label>
                        </a>
                        <span><i class="uil-check-circle"></i>{{ educationitems.point }}
                          {{ $t("point.point") }}</span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>

              <template v-for="(content_program, subIndex) in item.contents_programs">
                <template v-if="content_program.is_program == 0">
                  <ul class="course-curriculum-list bg-white mb-3">
                    <ContentItem v-if="content_program.file_category_id"
                      :certificate_id="content_program.certificate_id" :icon="content_program.file_category.icon"
                      :is_completed="item.unlocked_items.includes(content_program.id) ? true : false"
                      :is_locked="item.locked_items.includes(content_program.id) ? true : false"
                      :is_unlocked="item.current_item == content_program.id ? true : false"
                      :name="$t(content_program.file_category.name)" :point="content_program.point"
                      :title="content_program.title" @linkClick="showContentModal(
                        content_program,
                        item.user_content_completed.includes(
                          content_program.id
                        )
                      )
                        " @show_certificate="showCertifcateModal(content_program.id)"
                      :can_open_contents_in_order="item.can_open_contents_in_order" :contentItem="content_program">
                    </ContentItem>
                  </ul>
                </template>

                <template v-else>
                  <ul class="course-curriculum" uk-accordion="multiple: true">
                    <li v-for="(subItem, index) in content_program.sections" :key="'sections_id' +
                      subItem.id +
                      '_index_' +
                      subIndex +
                      '_sub_index_' +
                      index
                      " class="mb-3">
                      <a class="uk-accordion-title" href="#">
                        {{ subItem.name }}</a>
                      <div class="uk-accordion-content">
                        <ul class="course-curriculum-list">
                          <template v-for="(educationContent, subIndex) in subItem.contents">
                            <template>
                              <ContentItem v-if="educationContent.file_category_id" :certificate_id="educationContent.certificate_id
                                " :icon="educationContent.file_category.icon"
                                :is_completed="item.unlocked_items.includes(educationContent.id) ? true : false"
                                :is_locked="item.locked_items.includes(educationContent.id)
                                  ? true
                                  : false
                                  " :is_unlocked="item.current_item == educationContent.id ? true : false"
                                :name="$t(educationContent.file_category.key_name)" :point="educationContent.point"
                                :title="educationContent.title" @linkClick="showContentModal(
                                  educationContent,
                                  item.user_content_completed.includes(
                                    educationContent.id
                                  )
                                )
                                  " @show_certificate="showCertifcateModal(educationContent.id)"
                                :can_open_contents_in_order="item.can_open_contents_in_order"
                                :contentItem="educationContent">
                              </ContentItem>
                            </template>
                          </template>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </template>
              </template>
              <div id="content_modal" bg-close="false" class="p-0" esc-close="false"
                style="z-index: 9999999; background: rgba(0, 0, 0, 1);" uk-modal>
                <div v-if="selected_content != null" class="uk-modal-dialog bg-transparent" style="width: 100%">
                  <button class="uk-modal-close-default text-black text-black-50 mt-1 mr-3" type="button" uk-close>
                    <span class="text-muted">  
                      {{ $t(" general.click_here_to_exit_the_training ") }}
                    </span>
                  </button>
                  <div class="uk-modal-header bg-transparent border-0 px-5">
                    <h2 class="uk-modal-title text-black">
                      {{ selected_content.title }}
                    </h2>
                  </div>
                  <div class="uk-modal-body py-0" uk-overflow-auto>
                    <div v-if="+selected_content.file_category_id === 2" class="video-responsive">
                      <img :src="selected_content.file.file_link
                        ? selected_content.file.file_link
                        : selected_content.file
                        " />
                    </div>
                    <div v-if="+selected_content.file_category_id === 7 ||
                      +selected_content.file_category_id === 6
                    " class="video-responsive">

                      <template>
                        <div class="video-time">
                          <span class="now">{{ video_now }}</span> /
                          <span class="total">{{ video_total }}</span>
                        </div>
                        <div class="artplayer-app"></div>
                      </template>
                    </div>

                    <div v-if="+selected_content.file_category_id === 12">
                      <template>
                        <ExamQuestionList :modal_name="'assignment_exam'" :modal_title="currentExam.title"
                          :exam="currentExam" @onSubmit="saveExam" @onClose="closeExam"></ExamQuestionList>
                      </template>
                    </div>
                    <div v-if="+selected_content.file_category_id === 13">
                      <template>
                        <QuestionList :modal_name="'assignment_survey'" :modal_title="currentSurvey.name"
                          :survey="currentSurvey" @onSubmit="saveSurvey" @onClose="closeExam"></QuestionList>
                      </template>
                    </div>
                    <div v-if="+selected_content.file_category_id === 15">
                      <template>
                        <CheckList :modal_name="'checklist'" :modal_title="currentCheckList[0].description"
                          :checkList="currentCheckList" @onSubmit="saveCheckList" @onClose="closeExam"></CheckList>
                      </template>
                    </div>
                    <div v-if="+selected_content.file_category_id === 16">
                      <template>
                        <!-- <Mission :modal_name="'checklist'" :modal_title="currentCheckList[0].description" -->
                        <Mission :modal_name="'mission'" :mission="missionDetail" @onSubmit="saveMission"
                          @onClose="closeExam"></Mission>
                      </template>
                    </div>
                    <div v-if="+selected_content.file_category_id === 17">
                      <div class="uk-width-5-5m uk-first-column" v-html="selected_content.information_content"></div>

                    </div>
                    <div v-if="+selected_content.file_category_id === 8" class="iframe-responsive">
                      <iframe :src="selected_content.url" :title="selected_content.title"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen frameborder="0" height="100vh" width="100vw">
                      </iframe>
                    </div>
                    <div v-if="+selected_content.file_category_id === 3" class="iframe-responsive">
                      <iframe :src="selected_content.file.file_link
                        ? selected_content.file.file_link
                        : selected_content.file
                        " :title="selected_content.title" frameborder="0" height="100vh" width="100vw">
                      </iframe>
                    </div>
                    <div v-if="[9].includes(selected_content.file_category_id)" class="iframe-responsive">
                      <iframe :src="selected_content.url" :title="selected_content.title" frameborder="0" height="100vh"
                        width="100vw">
                      </iframe>
                    </div>
                    <div v-if="+selected_content.file_category_id === 10" class="iframe-responsive">
                      <iframe :src="externalScormPackageItemUrl" :title="selected_content.title" frameborder="0"
                        height="100vh" width="100vw">
                      </iframe>
                    </div>
                    <div v-if="+selected_content.file_category_id === 1 && !secondFrame" class="iframe-responsive">
                      Loading
                      <iframe style="display:none" :src="scormPackageItemUrl" :title="selected_content.title"
                        frameborder=" 0" height="100vh" width="100vw" @load="loadedIframe()" allowfullscreen>
                      </iframe>
                    </div>
                    <div v-if="+selected_content.file_category_id === 1 && secondFrame" class="iframe-responsive">
                      <iframe :src="scormPackageItemUrl" :title="selected_content.title" frameborder="0" height="100vh"
                        width="100vw" allowfullscreen>
                      </iframe>
                    </div>
                    <div v-if="+selected_content.file_category_id === 5 || +selected_content.file_category_id === 4"
                      class="iframe-responsive">
                      <iframe :src="fixedPPtWordFile" width="1000" height="500" scrolling="no" frameborder="0"
                        webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                    <!-- <iframe
      src="https://view.officeapps.live.com/op/embed.aspx?src=http://api.zorunluegitimler.com/storage/content/files/7441a12e3ef398332ce5855a4a448d294fb49fd2/RLfapVeRDzgzYYkEACxW7XFOvJOqPOxmj6LtistS.pptx?postMessageEvents=true"
      width="1000" height="500" scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen
      allowfullscreen></iframe> -->
                  </div>
                  <div class="modal-footer" v-if="showContentFooter">
                    <div class="row d-flex justify-content-between align-items-center">
                      <div class="col-8">
                        <i class="icon-feather-check"></i>
                        {{ $t("education.completed_the_content") }}
                      </div>
                      <div class="col-4">
                        <span class="btn-night-mode-switch">
                          <label class="uk-switch" for="default-1">
                            <input type="checkbox" id="default-1" :disabled="isCompletionTimeout"
                              @change="contentCompletedTrigger" v-model="contentCompleted" />
                            <div class="uk-switch-slider"></div>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="assignment_certificate_modal" class="uk-modal-full" style="z-index: 999999999" uk-modal>
                <div class="uk-modal-dialog h-100 bggray d-flex flex-column justify-content-between">
                  <div class="uk-modal-header d-flex justify-content-end bggray">
                    <button class="btn btn-animated btn-success btn-animated-x uk-first-column mr-1" type="button"
                      @click="handleToDownloadPdf">
                      <span class="btn-inner--visible"><i class="uil-file-download"></i> {{ $t("certification.pdf")
                        }}
                      </span>
                      <span class="btn-inner--hidden">
                        <i class="uil-cloud-download"></i>
                      </span>
                    </button>
                    <button class="uk-button uk-button-default small uk-modal-close mr-1" type="button">
                      <i class="uil-times"></i> {{ $t("general.close") }}
                    </button>
                  </div>
                  <iframe :src="pdf_preview_link" style="width: 100%;height: 100%;">
                  </iframe>
                  <div class="uk-modal-footer uk-text-right bggray">
                    <button class="uk-button uk-button-default small uk-modal-close" type="button">
                      <i class="uil-times"></i> {{ $t("general.close") }}
                    </button>
                  </div>
                </div>
              </div>
            </li>

            <!-- Feedbacks -->
            <li v-if="item.has_feedbacks" class="course-description-content">
              <div class="comments mt-4">
                <h4>
                  {{ this.$t('general.experience_sharing') }}
                  <span class="comments-amount">({{ item.approved_comments.length }}
                    {{ $t("education.Sharing") }})</span>
                </h4>
                <ul>

                  <template v-for="( comment, index ) in item.approved_comments ">
                    <Comments v-if="'comment_index_' + index" :id="'comment_' + comment.id"
                      :key="'comment_index_' + index" :comment="comment.text" :image="comment.user.photo_link"
                      :status_id="comment.status_id" :name="comment.user.name + ' ' + comment.user.surname" :title="typeof comment.user.position !== 'undefined' &&
                        comment.user.position != null
                        ? comment.user.position.name
                        : ''
                        ">
                    </Comments>
                  </template>
                </ul>
              </div>
              <div class="comments">
                <ul>
                  <li>
                    <div class="comments-avatar">
                      <img :src="this.authUser.photo" alt="" />
                    </div>
                    <div class="comment-content">
                      <div class="uk-grid-small" uk-grid @submit.prevent="">
                        <div class="uk-width-1-1@s">
                          <textarea v-model="comment_text" class="uk-textarea pt-2"
                            placeholder="Buraya deneyimlerini yaz..." style="height: 160px"></textarea>
                        </div>
                        <div class="uk-grid-margin">
                          <button class="btn btn-default" type="button" value="Gönder" @click="makeComment">
                            {{ $t("messages.send") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <!-- Gifts -->
            <li v-if="item.has_present">
              <h4 class="my-4">{{ $t("education.Gift_list") }}</h4>

              <div id="book-popular">
                <div class="bg-gradient-info uk-light uk-alert" uk-alert="">
                  <a class="uk-alert-close uk-icon uk-close" uk-close="">
                    <svg data-svg="close-icon" height="14" viewBox="0 0 14 14" width="14"
                      xmlns="http://www.w3.org/2000/svg">
                      <line fill="none" stroke="#000" stroke-width="1.1" x1="1" x2="13" y1="1" y2="13"></line>
                      <line fill="none" stroke="#000" stroke-width="1.1" x1="13" x2="1" y1="1" y2="13"></line>
                    </svg>
                  </a>
                  <div class="d-flex">
                    <i class="icon-feather-alert-circle"></i>
                    <p>{{ $t("education.Gift_text") }}</p>
                  </div>
                </div>

                <div v-if="giftList.length && this.authUser.id == giftList[0].id"
                  class="bg-gradient-success uk-light uk-alert" uk-alert="">
                  <a class="uk-alert-close uk-icon uk-close" uk-close="">
                    <svg data-svg="close-icon" height="14" viewBox="0 0 14 14" width="14"
                      xmlns="http://www.w3.org/2000/svg">
                      <line fill="none" stroke="#000" stroke-width="1.1" x1="1" x2="13" y1="1" y2="13"></line>
                      <line fill="none" stroke="#000" stroke-width="1.1" x1="13" x2="1" y1="1" y2="13"></line>
                    </svg>
                  </a>
                  <div class="d-flex align-items-center">
                    <i class="icon-feather-gift"></i>
                    <p>
                      Listede 1. sıradasın.
                      <b>"{{ giftList[0].gift.name }}"</b> adlı hediyeyi
                      kazanmaya <b>adaysın!</b>
                    </p>
                  </div>
                </div>
                <div v-for="( item, gift_list_index ) in giftList " :key="'gift_section_' + gift_list_index"
                  class="book-popular-card">
                  <img :src="item.gift.image" alt="" class="cover-img" />
                  <div class="book-details">
                    <p>
                      {{ gift_list_index + 1 }} .
                      {{ $t("education.gift_order") }}
                    </p>
                    <h4>{{ item.gift.name }}</h4>
                    <p v-if="item.fullname" class="user highlight">
                      <img :src="item.image" alt="" />
                      <span>{{ item.fullname }} [Aday]</span>
                    </p>
                    <p v-else class="user highlight">
                      <span>{{ $t("gift.no_candidates") }}</span>
                    </p>
                  </div>
                </div>

                <a class="btn btn-success d-block" href="#modal-overflow" uk-toggle>
                  <i class="uil-gift"></i> {{ $t("education.All_gift_list") }}
                </a>

                <div id="modal-overflow" ref="modalRef" uk-modal>
                  <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                      <h2 class="uk-modal-title">
                        {{ $t("education.All_Gift") }}
                      </h2>
                    </div>

                    <div class="uk-modal-body" uk-overflow-auto>
                      <div v-for="( item, index ) in giftList " :key="'gift_section_modal_' + index"
                        class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                        <div v-if="item.image" class="uk-width-auto position-relative">
                          <img :src="item.image" class="rounded avatar-sm" />
                        </div>
                        <div class="uk-width-expand">
                          <p v-if="item.fullname" class="uk-text-truncate mb-0">
                            <span>{{ index + 1 }}. {{ $t("education.Next") }}</span><br />
                            {{ item.fullname }}
                          </p>
                          <p v-else class="uk-text-truncate mb-0">
                            {{ $t("gift.no_candidates") }}
                          </p>
                          <p>
                            <b>{{ item.gift.name }}</b>
                          </p>
                        </div>
                        <div v-if="item.fullname">
                          <span class="btn-option userlist">
                            <i class="icon-feather-more-vertical"></i>
                          </span>
                          <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                            <ul>
                              <li @click.prevent="goLink('user.show', item.id)">
                                <span>
                                  <i class="uil-user"></i>
                                  {{ $t("education.View_Profile") }}
                                </span>
                              </li>
                              <!--  <li>
                                 <span>
                                   <i class="uil-bolt-alt"></i>
                                   {{ $t("education.Send_Duel_Invitation") }}</span
                                 >
                               </li> -->
                            </ul>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-3" />

              <template v-if="leaderList">
                <h4 class="mt-5 mb-3">{{ $t("education.Best_table") }}</h4>

                <ul class="uk-list uk-list-striped leaderboard">
                  <li v-for="( item, index ) in leaderList " :key="'leader_section' + index" class="">
                    <i class="uil-user-circle"></i>{{ index + 1 }}.
                    {{ item.fullname }}
                    <span>{{ item.point.point }}</span>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
        <!-- Left Sticky Nav -->
        <div class="uk-width-1-3@m uk-flex-first">
          <div class="course-card-trailer">
            <div class="course-thumbnail text-center">
              <img :alt="item.name" :src="item.cover_image_link" />
            </div>

            <div class="p-3">
              <p style="font-size: 1rem" v-if="!item.provider">
                %{{ checkCompletion(item.completion_percentage) }}
                {{ $t("education.Completed") }}
              </p>
              <div class="course-progressbar mb-4" v-if="!item.provider">
                <div :class="[
                  item.completion_percentage >= 75 ? 'bg-success' : '',
                  item.completion_percentage < 75 &&
                    item.completion_percentage >= 50
                    ? 'bg-warning'
                    : '',
                ]
                  " :style="'width:' + checkCompletion(item.completion_percentage) + '%'
                    " :uk-tooltip="'title:%' +
                      checkCompletion(item.completion_percentage) +
                      ' ' +
                      $t('education.Completed') +
                      '; pos: top-center'
                      " class="course-progressbar-filler continue"></div>
              </div>
              <div class="uk-child-width-2-2 uk-grid-small mb-4" uk-grid>
                <div v-if="item.is_start">
                  <div v-if="(item.completion_percentage !== 100) && !item.is_expired">
                    <a class="uk-width-1-1 btn text-white btn-danger transition-3d-hover" href="#"
                      @click.prevent="startFromUnlockedEducation">
                      <i class="uil-forward"></i>
                      <span v-if="item.completion_percentage === 0">{{ $t("education.Start_Education") }}</span>
                      <span v-else>{{
                        $t("education.Continue_Where_I_Stay")
                      }}</span>
                    </a>
                  </div>
                  <div v-else-if="item.can_be_watched_again && (item.completion_percentage === 100)">
                    <a class="uk-width-1-1 btn text-white btn-success transition-3d-hover" href="#"
                      @click.prevent="startFirstEducation">
                      <i class="uil-forward"></i>
                      <span> {{ $t("general.watch") }}
                      </span>
                    </a>
                  </div>
                  <div v-else>
                    <a class="uk-width-1-1 btn text-white btn-danger transition-3d-hover" href="#">
                      <i class="uil-forward"></i>
                      <span>{{ $t("general.cantwatch") }}</span>
                    </a>
                  </div>
                </div>
                <div v-else>
                  <a class="uk-width-1-1 btn text-white btn-danger transition-3d-hover"
                    uk-tooltip="Eğitim başlangıç tarihi henüz gelmemiştir." href="#">
                    <i class="uil-forward"></i>
                    <span>{{ $t("general.cantwatch") }}</span>
                  </a>
                </div>
                <div v-if="CollectionVisible">
                  <a class="uk-width-1-1 text-white btn btn-primary transition-3d-hover" @click="addCollection">
                    <i class="uil-brightness-plus"></i>
                    {{ $t("education.Add_collection") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardLayout from "@/view/layouts/DashboardLayout";
import Section from "@/view/components/educationDetail/Section";
import Tags from "@/view/components/search/Tags";
import Comments from "@/view/components/educationDetail/Comments";
import ContentItem from "@/view/components/assignmentDetail/ContentItem";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import { mapGetters } from "vuex";
import {
  CREATE_ITEM,
  ERROR as REST_ERROR,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  LOADING as REST_LOADING,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";

import AddCollectionModal from "@/view/components/educationDetail/AddCollectionModal";
import QuestionList from "@/view/components/survey/QuestionList";
import EvaluationQuestionList from "@/view/components/survey/EvaluationQuestionList";
import ExamQuestionList from "@/view/components/exam/ExamQuestionList";
import CheckList from "@/view/components/checkList/CheckListDetail";
import Mission from "@/view/components/mission/MissionDetail";
import Rating from "@/view/components/survey/Rating";
import StarRating from "vue-star-rating";
import axios from "axios";
import Test from "@/view/test/Test";
import Artplayer from "artplayer";

import {
  GET_COUNT as NOTIFICATION_GET_COUNT,
  GET_ITEMS as NOTIFICATION_GET_ITEMS,
  MODULE_NAME as NOTIFICATION_MODULE_NAME,
} from "@/core/services/store/notification.module";

export default {
  name: "EducationDetails",
  components: {
    Test,
    DashboardLayout,
    Section,
    Tags,
    Comments,
    AddCollectionModal,
    QuestionList,
    ExamQuestionList,
    EvaluationQuestionList,
    CheckList,
    Mission,
    Rating,
    ContentItem,
    StarRating,
    DefaultLoading,
  },
  data() {
    return {
      isCompletionTimeout: false,
      fixedPPtWordFile: '',
      secondFrame: false,
      restUrl: "api/assignment/",
      certificateUrl: "api/assignment/user-certificate/",
      externalScormUrl: "api/assignment/external-scorm/",
      assignmentExamUrl: "api/assignment/",
      assignmentSurveyUrl: "api/assignment/",
      videoContentUrl: "api/assignment/",
      scormUrl: "api/content/get-scorm/",
      surveyUrl: "api/assignment/survey/",
      ratingUrl: "api/assignment/rating/",
      commentUrl: "api/assignment/comment/",
      completeContentUrl: "api/assignment/complete-content/",
      startContentUrl: "api/assignment/start-content/",
      updateContentUrl: "api/assignment/update-content/",
      collectionUrl: "api/collection",
      giftUrl: "api/assignment/gift-list/",
      leaderUrl: "api/assignment/leader-table/",
      collectionContentUrl: "api/assignment/collection-content/",
      apiURL: "api/user/self",
      giftList: [],
      leaderList: [],
      externalScormPackageItemUrl: "",
      currentExam: "",
      currentSurvey: "",
      scormPackageItemUrl: "",
      is_add_collection: false,
      current_certificate_content_id: null,
      collections: [],
      collection_id: null,
      collectionName: null,
      is_add_new_collection: false,
      collection_video: null,
      collection_content_ids: [],
      collection_sound: null,
      successMessage: null,
      errorMessage: null,
      additional: [],
      item: [],
      comment_text: "",
      selected_content: null,
      exam_completed: false,
      // certificate_html: "",
      pdf_preview_link: '',
      certificate_download_link: null,
      video_now: "00:00",
      video_total: "00:00",
      rating_star_points: [
        23,
        2,
        14,
        17,
        0,
        19,
        10,
        34,
        7,
        50,
        23,
        43,
        38,
        50,
        36,
        34,
        46,
        19,
        31,
        17,
      ],
      star_increment: 0.1,
      unlocked_education: null,
      isExternalScorm: false,
      isScorm: false,
      option: {
        url: "",
        poster: "",
        title: "",
        screenshot: true,
        isLive: true,
        autoplay: true,
        autoSize: true,
        playbackRate: true,
        moreVideoAttr: {
          crossOrigin: "anonymous",
        },
        hotkey: false,
        fullscreen: true,
        fullscreenWeb: true,
        miniProgressBar: true,
      },
      style: {
        width: "600px",
        height: "400px",
        margin: "60px auto 0",
      },
      contentUpdating: false,
      currentDuration: false,
      updateContentData: {},
      contentCompleted: false,
      showContentFooter: false,
      manuelCompleteContentIds: [2, 3, 8, 9, 5, 4],
      currentCheckList: '',
      missionDetail: {}
    };
  },
  computed: {
    ...mapGetters({
      assignment_loading: REST_LOADING,
    }),
    survey_name() {
      if (this.item.evaluation_survey) {
        return this.item.evaluation_survey.name;
      }
      if (this.item) {
        return this.item.name + " " + this.$t("general.evaluation_survey");
      }
    },
    restError: {
      get() {
        return this.$store.getters[REST_ERROR];
      },
      set(value) {
      },
    },
  },

  methods: {
    loadedIframe() {
      setTimeout(() => {
        this.secondFrame = true;
      }, 8000);
    },
    contentCompletedTrigger() {
      this.completeContent(this.selected_content);
      setTimeout(() => {
        UIkit.modal("#content_modal").hide();
      }, 400);
    },
    closeContentModal() {
      if (!this.item.is_completed) {
        let self = this;

        if (+self.selected_content.file_category_id === 12) {
          return false;
        }

        if (+self.selected_content.file_category_id === 1 || (+self.selected_content.file_category_id === 5 && self.is_powerpoint_scorm_file)) {
          self.getAssignmentItem();
        }

        if (
          +self.selected_content.file_category_id === 7 ||
          +self.selected_content.file_category_id === 6
        ) {
          self.updateContent();
        }
        if (![7, 6].includes(self.selected_content?.file_category_id)) {
          self.completeContent(self.selected_content);
        }
        self.selected_content = null;
        this.externalScormPackageItemUrl = "";
        this.scormPackageItemUrl = "";
        self.getAssignmentItem();
      }
      UIkit.modal("#content_modal").hide();
    },
    checkCompletion(percentage) {
      let completionPercentage = 0;
      if (percentage === undefined) {
        return completionPercentage;
      }

      completionPercentage = percentage >= 100 ? 100 : percentage;

      return completionPercentage;
    },
    getInstance(art) {
    },
    goLink(name, params) {
      if (params)
        this.$router.push({ name: name, params: { id: params } });
      UIkit.modal(this.$refs["modalRef"]).hide(0);
    },
    addCollectionModalSubmit(payload) {
      if (!this.CollectionVisible) {
        return false;
      }
      let self = this;

      this.$refs["addCollectionModal"].closeModal();

      if (payload.success) {
        self.successMessage = self.$t("education.collection_name_success");

        let temp = [...self.collections];
        temp.push({
          id: payload.data.id,
          name: payload.data.name,
        });
        self.tempId = payload.data.id;
        self.collections = [...temp];
        this.collection_id = self.tempId;
      } else {
        self.errorMessage = payload.message;
      }
    },
    onsubmit() {
      if (!this.collection_id) {
        this.errorMessage = this.$t("education.please_add_collection_name");
        return false;
      }

      if (!this.collection_content_ids.length) {
        this.errorMessage = this.$t("education.please_select_contents");
        return false;
      }
      this.$store
        .dispatch(CREATE_ITEM, {
          url: this.collectionContentUrl + this.$route.params.id,
          contents: {
            content_ids: this.collection_content_ids,
            collection_id: this.collection_id,
          },
        })
        .then(() => {
          if (!this.errors) {
            this.successMessage = this.$t("education.collection_created");
          }
          this.is_add_collection = false;
        });
    },
    saveEvaluationSurvey(data, callback) {
      let self = this;
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: this.surveyUrl + this.$route.params.id,
          contents: data,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.item.survey_count = 1;
            this.$modal.hide("survey_list_modal");

            // if (self.item.is_completed == true) {
            // self.$router.push({ name: "user.my-assignment" });
            // }
          }
          callback(result);
          return true;
        });
    },
    saveSurvey(data, callback) {
      let self = this;
      let content = this.selected_content;
      let url = `${self.assignmentSurveyUrl}${self.$route.params.id}/content/${content.id}/survey/result`;
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: url,
          contents: data,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.$modal.hide("content_modal");
          }
          callback(result);
          return true;
        });
    },

    closeExam(data) {
      if (data) {
        UIkit.modal("#content_modal").hide();
      }
    },
    closeSurvey(data) {
      if (data) {
        UIkit.modal("#content_modal").hide();
      }
    },
    saveExam(data, callback) {
      let self = this;
      let selectedContent = this.selected_content;
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: `${self.assignmentExamUrl}${self.$route.params.id}/content/${selectedContent.id}/exam/result`,
          contents: data,
          acceptPromise: false,
        })
        .then((result) => {
          self.exam_completed = true;
          callback(result);
          return true;
        });
    },
    saveCheckList(data, callback) {
      let self = this;
      let selectedContent = this.selected_content;
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: `${self.assignmentExamUrl}${self.$route.params.id}/content/${selectedContent.id}/checkList/result`,
          contents: data,
          acceptPromise: false,
        })
        .then((result) => {
          self.exam_completed = true;
          callback(result);
          return true;
        });
    },
    saveMission(data, callback) {
      let self = this;
      let selectedContent = this.selected_content;
      // let formData = new FormData();
      // formData.append("answer_file", data.userAnswer);
      console.log(data);
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: `${self.assignmentExamUrl}${self.$route.params.id}/content/${selectedContent.id}/mission/result`,
          contents: data.userAnswer,
          acceptPromise: false,
        })
        .then((result) => {
          self.exam_completed = true;
          callback(result);
          return true;
        });
    },
    saveRating(data, callback) {
      let self = this;
      let formData = new FormData();
      formData.append("rating", data.rating);
      formData.append("comment", data.comment);
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: this.ratingUrl + this.$route.params.id,
          contents: formData,
        })
        .then((result) => {
          if (result && result.status) {
            this.item.total_rating = result.data.total_rating;
            this.$modal.hide("rate_education_modal");
          }
          callback(self.restError);
          return true;
        });
    },
    makeComment() {
      if (this.comment_text.length < 2) {
        this.errorMessage = this.$t("validation.required_field").replace(
          ":NAME",
          this.$t("assignment.comment")
        );
        return false;
      }
      let formData = new FormData();
      formData.append("comment", this.comment_text);
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: this.commentUrl + this.$route.params.id,
          contents: formData,
        })
        .then((result) => {
          if (!this.restError) {
            this.comment_text = "";
            if (Object.keys(result.data).length)
              this.item.approved_comments.unshift(result.data);
            this.successMessage = this.$t("general.successfully_created");
          } else this.errorMessage = this.restError;
        });
    },
    addCollection() {
      this.is_add_collection = true;
    },
    resetMessages() {
      this.errorMessage = null;
      this.successMessage = null;
    },
    showCertifcateModal(content_id) {
      let self = this;
      self.certificate_html = "";
      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: self.certificateUrl + self.$route.params.id + "/" + content_id,
          acceptPromise: false,
        })
        .then((result) => {
          if (!self.restError && result.status) {
            self.pdf_preview_link = result.data.pdf_preview_link;
            // self.current_certificate_content_id = content_id;
            self.certificate_download_link = result.data.pdf_download_link;
            UIkit.modal("#assignment_certificate_modal").show();
          } else self.errorMessage = self.restError;
        });
    },
    handleToDownloadPdf() {
      window.open(this.certificate_download_link, "_blank");
      UIkit.modal("#assignment_certificate_modal").hide();
    },
    showContentModal(selected_content, is_completed) {
      console.log(selected_content);
      this.missionDetail = selected_content.content_mission;
      if ((new Date(this.item.start_date).getTime() - new Date().getTime() > 0) ||
        (new Date(this.item.end_date).getTime() - new Date().getTime() < 0) ||
        (!this.item.can_be_watched_again && this.item.is_completed && this.item.completion_percentage === 100)
        || (!this.item.is_completed && this.item.is_expired))
        return
      if (selected_content?.pivot?.timed_content_type === 'timed_content' && ((new Date(selected_content?.pivot?.starts_at).getTime() - new Date().getTime() > 0)
        || (new Date(selected_content?.pivot?.ends_at).getTime() - new Date().getTime() < 0))) {
        return
      }
      this.isExternalScorm = false;
      this.contentCompleted = false;
      this.isScorm = false;
      this.selected_content = selected_content;
      if (!this.item.is_completed) {
        this.setStartedAt();
        this.startContent(selected_content);
      }
      if (
        this.manuelCompleteContentIds.includes(
          selected_content.file_category_id
        )
      ) {
        let pptContent = null
        this.item.contents_programs.forEach(program => {
          if (program.id === selected_content.id)
            pptContent = program;
        });
        if (pptContent && pptContent.hasOwnProperty('completion_timeout') && pptContent.completion_timeout) {
          this.isCompletionTimeout = true;
          let completetionTime = pptContent.completion_timeout * 1000 * 60;
          setTimeout(() => {
            this.isCompletionTimeout = false;
          }, completetionTime);
        }
        this.showContentFooter = true;
      } else {
        this.showContentFooter = false;
      }

      if ([4, 5].includes(selected_content.file_category_id) && !selected_content.is_powerpoint_scorm_file) {
        if (selected_content.file_category_id === 5 && this.item.contents_programs[0].is_powerpoint_scorm_file) {
          selected_content.file_category_id = 1;
          this.showContentFooter = false;
        } else {
          this.fixedPPtWordFile = 'https://view.officeapps.live.com/op/embed.aspx?src=' + selected_content.file.file_link + '?postMessageEvents=true';
        }
      }
      if (selected_content.file_category_id === 1 || (selected_content.file_category_id === 5 && selected_content.is_powerpoint_scorm_file)) {
        this.isScorm = true;
        this.getScormEducationUrl(selected_content.id);
      }

      if (selected_content.file_category_id === 18) {
        window.open(this.item?.educations[0].webinar?.join_url, '_blank');
        return false
      }

      if (
        selected_content.file_category_id === 7 ||
        selected_content.file_category_id === 6
      ) {
        let element = document.getElementsByClassName("artplayer-app");
        this.getVideoWhereIStay(selected_content.id);
      }

      if (selected_content.file_category_id === 10) {
        this.isExternalScorm = true;
        this.getExternalScormUrl(selected_content.id);
      }

      if (selected_content.file_category_id === 12) {
        this.getExamContent(selected_content.id);
      }

      if (selected_content.file_category_id === 13) {
        this.getSurveyContent(selected_content.id);
      }
      if (selected_content.file_category_id === 17) {
        console.log(this.selected_content);
        this.showContentFooter = true;
        // this.getCheckListItem(selected_content.id);
      }
      UIkit.modal("#content_modal").show();
      // if (selected_content.file_category_id === 1) {
      //   setTimeout(() => {
      //     this.secondFrame = true;

      //   }, 3000);
      // }
    },
    downloadURI(uri) {
      window.open(uri);
      this.successMessage = this.$t("general.content_download_successfully");
    },
    setStartedAt() {
      let self = this;
      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: self.restUrl + "started/" + self.$route.params.id,
        contents: {
          type: "started",
        },
      });
    },
    setCompletedAt() {
      let self = this;
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: self.restUrl + "completed/" + self.$route.params.id,
          contents: {
            type: "completed",
          },
        })
    },
    async completeContent(education, duration = null) {
      console.log(education);
      let self = this;
      if (self.item.is_expired === true) return false;
      if (
        this.manuelCompleteContentIds.includes(
          education?.file_category_id) && !self.contentCompleted
      ) {
        return false;
      }

      let formData = new FormData();
      formData.append("content_id", education.id);
      formData.append("duration", duration);
      await this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url:
            self.completeContentUrl +
            self.$route.params.id +
            "/" +
            education?.id,
          contents: formData,
        })
        .then((result) => {
          if (result.status) {
            let data = result.data;
            self.item.completion_percentage = data.completion_percentage;
            self.item.user_content_completed = data.user_content_completed;
            self.item.user_content_completed_count =
              data.user_content_completed_count;
            self.item.locked_items = data.locked_items;
            self.item.unlocked_items = data.unlocked_items;
            self.item.current_item = data.current_item;

            if (self.item.is_completed) {
              self.setCompletedAt();
            }

            self.unlocked_education = self.getActiveEducationId(
              self.item.contents_programs,
              data.user_content_completed
            );
          } else {
            self.errorMessage = self.restError;
          }
          this.getAssignmentItem();
        });

      await this.$store.dispatch(
        NOTIFICATION_MODULE_NAME + "/" + NOTIFICATION_GET_COUNT,
        {}
      );

      await this.$store.dispatch(
        NOTIFICATION_MODULE_NAME + "/" + NOTIFICATION_GET_ITEMS,
        {}
      );
    },
    updateContent() {
      let self = this;
      if (self.item.is_expired === true) return false;
      self.contentUpdating = true;

      let formData = this.updateContentData;

      if (!formData || !formData.id) return false;
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url:
            self.updateContentUrl + self.$route.params.id + "/" + formData.id,
          contents: formData,
          noLoading: true,
        })
        .then((result) => {
          self.contentUpdating = false;
        });
    },
    startContent(education) {
      let self = this;
      if (self.item.is_expired === true) return false;
      let formData = new FormData();

      formData.append("content_id", education.id);
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url:
            self.startContentUrl + self.$route.params.id + "/" + education.id,
          contents: formData,
        })
        .then((result) => {
          if (!self.restError && result.status) {
            let data = result.data;
            // self.item.completion_percentage = data.completion_percentage;
            // self.item.user_content_completed = data.user_content_completed;
            // self.item.user_content_completed_count = data.user_content_completed_count;
            // self.item.is_completed = data.is_completed;

            // self.unlocked_education = self.getActiveEducationId(
            //     self.item.contents_programs,
            //     data.user_content_completed
            // );
          } else {
            self.errorMessage = self.restError;
          }
        });
    },
    showSurveyModal() {
      let self = this;
      if (
        typeof this.selected_content !== "undefined" &&
        this.selected_content !== null
      ) {
        let educations = this.item.educations;
        let selected_id = this.selected_content.id;
        if (
          self.item.is_completed &&
          self.item.survey_required &&
          educations.length &&
          educations[educations.length - 1].id === selected_id &&
          self.item.survey_count === 0
        ) {
          self.$modal.show("survey_list_modal");
        }
      } else {
        let educations = this.item.educations;
        if (
          self.item.is_completed &&
          self.item.survey_required &&
          educations.length &&
          self.item.survey_count === 0
        ) {
          self.$modal.show("survey_list_modal");
        }
      }
    },
    setModalListener() {
      let self = this;
      $("#content_modal").on({
        // 'show.uk.modal': function(){
        //
        // },
        "hide.uk.modal": function () {
          // if (!self.isAnzera) {
          //   self.setCompletedAt();
          // }
          if (
            self.selected_content &&
            +self.selected_content.file_category_id === 12 &&
            self.exam_completed !== true
          ) {
            // UIkit.modal("#content_modal").show();
            return false;
          }

          // if (+self.selected_content.file_category_id === 1) {
          //   self.getAssignmentItem();
          // }

          if (
            +self.selected_content.file_category_id === 6 ||
            +self.selected_content.file_category_id === 7
          ) {
            self.updateContent();
          }

          // if (![6, 7].includes(self.selected_content.file_category_id)) {
          //   self.completeContent(self.selected_content);
          // }
          if (![6, 7, 5].includes(self.selected_content?.file_category_id) && this.selected_content?.file_category_id !== 15 && !self.item?.is_completed) {
            self.completeContent(self.selected_content);
          } else if (![5, 4].includes(self.selected_content?.file_category_id)) {
            self.getAssignmentItem();
          }


          // if (!self.item.is_completed && self.selected_content.file_category_id === 10) {
          //   self.completeContent(self.selected_content);
          // }

          self.selected_content = null;
          this.anzeraPackageItemUrl = "";
          this.scormPackageItemUrl = "";
          // self.getAssignmentItem();
        },
      });
    },
    getCollectionItems() {
      let flag = false;
      if (JSON.parse(window.localStorage.getItem("modules"))?.length > 0) {
        JSON.parse(window.localStorage.getItem("modules")).forEach(data => {
          if (data === "collections") {
            flag = true;
          }
        });
      }
      if (!flag) return false;

      if (!this.CollectionVisible) {
        return false;
      }
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: self.collectionUrl,
          acceptPromise: true,
        })
        .then((result) => {
          if (result.status) {
            self.collections = result.data.my_collections;
          }
        });
    },
    itemGifts() {
      let self = this;
      let trainingId = self.$route.params.id;
      if (trainingId) {
        axios.get(self.giftUrl + trainingId, {}).then(function (response) {
          self.giftList = response.data;
        });
      }

    },
    itemLeaders() {
      let self = this;
      let trainingId = self.$route.params.id;
      axios.get(self.leaderUrl + trainingId, {}).then(function (response) {
        self.leaderList = response.data;
      });
    },
    getAssignmentItem() {
      let self = this;
      let trainingId = self.$route.params.id;
      if (trainingId) {
        self.$store
          .dispatch(GET_ITEM_DETAIL_BY_ID, {
            url: self.restUrl + trainingId,
            acceptPromise: false,
            showLoading: true,
          })
          .then((result) => {
            if (result.status) {
              self.item = result.data;
              console.log(result.data);
              if (self.item.contents_programs[0].file_category_id === 5 && self.item.contents_programs[0].is_powerpoint_scorm_file) {
                self.item.contents_programs[0].file_category_id = 1;
                self.item.contents_programs[0].file.file_category_id = 1
              }
              if (self.item.is_expired != true) {
                this.currentCheckList = result.data.contents_programs[0]?.content_check_list_items;
                this.unlocked_education = this.getActiveEducationId(
                  result.data?.contents_programs,
                  result.data?.user_content_completed
                );
              }
              if (self.item.has_present) {
                this.itemGifts();
              }

              if (self.item.appear_top) {
                this.itemLeaders();
              }
              this.showSurveyModal();
            }
          });
      } else {
        self.$router.go(-1);
      }
    },
    startFromUnlockedEducation() {
      let item = this.item.contents_programs.find(
        (item) => item.id === this.item.current_item
      );
      if (!item) {
        this.item.contents_programs.map((subItem) => {
          subItem.sections.map((sectionItem) => {
            let content = sectionItem.contents.find(
              (contentItem) => contentItem.id === this.item.current_item
            );
            if (content) {
              this.showContentModal(content, false);
            }
          });
        });

      } else {
        this.showContentModal(item, false);
      }
      return false;
    },
    startFirstEducation() {
      let item = this.item;
      this.showContentModal(item.educations[0], false);
      return true;
    },
    getActiveEducationId(educations, completed_educations) {
      for (let i = 0; i < educations.length; i++) {
        if (educations[i].is_program == 1) {
          for (let j = 0; j < educations[i].sections.length; j++) {
            let next_education = this.getActiveEducationId(
              educations[i].sections[j].contents,
              completed_educations
            );
            if (next_education != null) return next_education;
          }
        }
        if (!completed_educations?.includes(educations[i].id)) {
          return educations[i].id;
        }
      }
      return null;
    },
    getExternalScormUrl(content_id) {
      let self = this;
      let url =
        self.externalScormUrl +
        self.$route.params.id +
        "/content/" +
        content_id;

      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: url,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.externalScormPackageItemUrl = result.data;
            console.log(this.externalScormPackageItemUrl);
            return;
          }
        });
    },
    getExamContent(content_id) {
      let self = this;
      let url = `${self.assignmentExamUrl}${self.$route.params.id}/content/${content_id}/exam`;

      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: url,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.currentExam = result.data;
            return;
          }
        });
    },
    getSurveyContent(content_id) {
      let self = this;
      let url = `${self.assignmentSurveyUrl}${self.$route.params.id}/content/${content_id}/survey`;

      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: url,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.currentSurvey = result.data;
            return;
          }
        });
    },
    getVideoWhereIStay(content_id) {
      let self = this;
      let url =
        self.videoContentUrl +
        self.$route.params.id +
        "/content/" +
        content_id +
        "/stay";

      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: url,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.currentDuration = result.data;
          }
        });
    },
    getScormEducationUrl(content_id) {
      let self = this;
      let url =
        self.scormUrl + self.$route.params.id + "/content/" + content_id;

      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: url,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.scormPackageItemUrl = result.data;
            console.log(this.scormPackageItemUrl);
            return;
          }
        });
    },
    time_format(time) {
      let minute = Math.floor(time / 60);
      minute = minute.toString().padStart(2, "0");
      time = Math.floor(time) % 60;
      time = time.toString().padStart(2, "0");
      return minute + ":" + time;
    },
  },
  watch: {
    selected_content(content) {
      if (content) {
        if (
          +content.file_category_id === 6 ||
          +content.file_category_id === 7
        ) {
          let self = this;
          setTimeout(() => {
            let content_complete_status = self.item.unlocked_items.find(
              (x) => x === content.id
            );
            if (!content_complete_status && content.video_can_be_fast_forwarded)
              content_complete_status = true;
            if (!content_complete_status && self.item.is_completed && content.video_is_completed_it_can_be_fast_forwarded)
              content_complete_status = true;
            let art = new Artplayer({
              container: ".artplayer-app",
              url: content.file.file_link
                ? content.file.file_link
                : content.file,
              // poster: content.cover_photo_link ?? content.file.file_link,
              title: content.title,
              screenshot: true,
              moreVideoAttr: {
                crossOrigin: "anonymous",
              },
              // isLive: !content_complete_status,
              isLive: content_complete_status ? false : true,
              hotkey: false,
              autoplay: false,
              pip: true,
              autoSize: true,
              autoMini: true,
              screenshot: true,
              setting: false,
              playbackRate: true,
              aspectRatio: true,
              fullscreen: true,
              fullscreenWeb: true,
              subtitleOffset: true,
              miniProgressBar: true,
              icons: {
                state: '<i class="uil-play"></i>',
              },
              lang: "tr",
            });

            art.on("ready", () => {
              self.video_now = self.time_format(art.currentTime);
              self.video_total = self.time_format(art.duration);
              setTimeout(() => {
                art.currentTime = Number(self.currentDuration);
                if (
                  Math.round(+self.currentDuration) ===
                  Math.round(+art.duration)
                ) {
                  art.currentTime = 0;
                }
              }, 1200);
            });
            art.on("video:timeupdate", (...args) => {
              setTimeout(() => {
                let duration = (art.currentTime * 100) / art.duration;
                self.video_now = self.time_format(art.currentTime);
                self.video_total = self.time_format(art.duration);
                self.updateContentData = {
                  id: content.id,
                  completion_percentage: Math.round(duration),
                  duration: art.currentTime,
                };
              }, 1000);
            });
            art.on("video:pause", (...args) => {
              setTimeout(() => {
                const duration = (art.currentTime * 100) / art.duration;
                self.video_now = self.time_format(art.currentTime);
                self.video_total = self.time_format(art.duration);

                self.updateContentData = {
                  id: content.id,
                  completion_percentage: Math.round(duration),
                  duration: art.currentTime,
                };
                self.updateContent();
              }, 1200);
            });

            art.on("video:ended", () => {
              setTimeout(() => {
                let duration = art.duration / 60;
                self.completeContent(content, duration);
              }, 3000);
            });
          }, 2000);
        }
      }
    },
    collection_id(newValue) {
      if (+newValue === 0) {
        this.is_add_collection = true;
        this.$modal.show("open_to_add_collection_name");
        this.collection_id = newValue.id;
      }
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessage() {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 3000);
  },
  mounted() {
    // const root = document.querySelector(':root');
    // root.style.setProperty('--backgroundColor', 'black');
    this.getCollectionItems();


    this.getAssignmentItem();
    this.setModalListener();
  },
  beforeDestroy() {
    const modal = UIkit.modal("#content_modal");
    const certificate_modal = UIkit.modal("#assignment_certificate_modal");
    if (modal) modal.$destroy(true);
    if (certificate_modal) certificate_modal.$destroy(true);
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.artplayer-app {
  width: 100vh;
  height: 100vh;
}

::v-deep #open_to_add_collection_name .vm--modal {
  height: 480px !important;
}

#content_modal {
  background: rgb(255, 255, 255) none repeat scroll 0% 0% !important;
}

.art-video-player.art-contextmenu-show .art-contextmenus {
  display: none;
}

.video-time {
  position: absolute;
  top: 5%;
  left: 1%;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 6px 10px;
}

@media only screen and (max-width: 600px) {
  .uk-first-column {
    margin-left: 0 !important;
  }
}
</style>
<style>
/* :root {
  --backgroundColor: #eddd64;
  --white: #121110;
} */

/* .course-details-info {
  color: var(--backgroundColor);
  background-color: var(--backgroundColor);
} */
</style>
