<template>
    <div :class="modal_name ? 'p-4' : 'px-4 pt-5 pb-4'">
        <button v-if="!modal_name" class="uk-modal-close-default" type="button" uk-close @click="closeModal"></button>

        <div class="container">
            <div class="col-md-8 offset-md-2">
                <div class="section-header border-0 uk-flex-middle">
                    <div class="section-header-left d-flex align-items-center">
                        <!-- <img src="@/assets/images/img-checklist.png" /> -->
                        <p class="lead text-left ml-3">{{ modal_title }}</p>
                    </div>
                </div>
                <div v-if="errorMessages.length" class="uk-alert-danger" uk-alert>
                    <template v-for="(error, index) in errorMessages">
                        <span :key="index" v-if="error != null">
                            <a class="uk-alert-close" uk-close></a>
                            <p style="color: red" class="m-0">
                                <i class="uil-exclamation-triangle"></i> {{ error }}
                            </p>
                        </span>
                    </template>
                </div>
                <div v-if="successMessage" class="uk-alert-success" uk-alert>
                    <a class="uk-alert-close" uk-close></a>
                    <p style="color: green">
                        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
                    </p>
                </div>

                <!-- Card header -->
                <div>
                    <label> {{ mission.mission_description }}
                        <textarea v-if="answer_type === 'text'" style="margin-top: 1rem" name="short_description"
                            id="short_description" v-model="userAnswer.answer_text" class="form-control"></textarea>

                    </label>

                </div>
                <div class="card-body actions-toolbar border-0" style="max-height: 50vh;overflow-y: auto">
                    <ul class="list-group">
                        <template v-if="answer_type === 'file'">
                            <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i> Dosya yükle</label>
                            <strong style="text-align: center">{{ $t("general.or") }}</strong>
                            <span class="dragBox">
                                <i :class="selected_content_type.icon"></i>
                                Sürükle
                                <input type="file" draggable="true" @dragover.prevent @dragenter="drag"
                                    @dragleave="drop" id="uploadFile" @change="droppedMission" />
                            </span>
                        </template>
                    </ul>
                </div>
                <div id="previewMission"></div>
                <div class="card-footer d-flex justify-content-between p-2">
                    <p class="mt-2 text-center"></p>
                    <div>
                        <button v-if="modal_name" @click="closeModal"
                            class="btn btn-sm btn-secondary button btn-pill cursor-pointer mr-2">
                            {{ $t("general.close") }}
                        </button>
                        <button @click="onSubmit" class="btn btn-sm btn-primary button btn-pill cursor-pointer"
                            v-if="!hideSubmit">
                            <i class="uil-check"></i> {{ $t("general.submit") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Template from "../../company/Mail/Template.vue";

export default {
    components: { Template },
    name: "Mission",
    props: {
        mission: {
            required: true,
            default:[],
        },
        modal_name: {
            required: true,
        },
        modal_title: {
            required: false,
        }
    },
    data() {
        return {
            errorMessages: [],
            successMessage: null,
            hideSubmit: false,
            answer_type: '',
            userAnswer: {
                mission_file: null,
                answer_text: null
            },

            acceptable_file_type: "*",
            selected_content_type: [],
        };
    },
    computed: {
    },
    methods: {
        dragNdrop(event) {
            let file = event.target.files[0];
            this.userAnswer.mission_file = file;
        },
        droppedMission(event) {
            let file = event.target.files[0];
            if (!file) return false;

            // if (!this.compareTypeCompatibality(file, this.acceptable_file_type)) {
            //   $("#uploadFile").val("");
            //   this.errorMessages.push("invalid file type");
            //   return false;
            // }
            this.userAnswer.mission_file = event.target.files[0];
            let fileName = URL.createObjectURL(event.target.files[0]);
            let preview = document.getElementById("previewMission");
            let previewImg = false;
            if (this.acceptable_file_type.includes("pdf"))
                previewImg = document.createElement("embed");
            if (this.acceptable_file_type.includes("image"))
                previewImg = document.createElement("img");
            if (
                file.type.includes("audio") &&
                this.acceptable_file_type.includes("audio")
            )
                previewImg = document.createElement("audio");
            if (
                file.type.includes("video") &&
                this.acceptable_file_type.includes("video")
            )
                previewImg = document.createElement("video");
            if (previewImg && previewImg.controls != undefined)
                previewImg.controls = true;
            let docInfo = document.createElement("p");
            docInfo.innerHTML = this.docInfo(file);
            if (previewImg) previewImg.setAttribute("src", fileName);
            if (previewImg && previewImg.tagName.toUpperCase() == "EMBED") {
                previewImg.style.width = "100%";
                previewImg.style.minHeight = "800px";
                previewImg.setAttribute("type", file.type);
            }
            preview.innerHTML = "";
            preview.appendChild(docInfo);
            if (previewImg) preview.appendChild(previewImg);
            this.drop(event);
        },
        drag(event) {
            event.target.parentNode.className = "draging dragBox";
        },
        drop(event) {
            event.target.parentNode.className = "dragBox";
        },
        docInfo(file) {
            let file_complete_name = file.name;
            let file_name_split = file_complete_name.split(".");
            let file_extension = file_name_split.pop().toLowerCase();
            let file_name = file_name_split.join(".");
            let file_size = this.bytesToSize(file.size);
            return (
                '<div class="file-info"><div>Format: <span>' +
                file_extension.toUpperCase() +
                "</span></div><div>Doküman Adı: <span>" +
                file_name +
                "</span></div><div>Boyut: <span>" +
                file_size +
                "</span></div></div>"
            );
        },
        onSubmit() {
            let self = this;
            let formData = new FormData();
            console.log(this.userAnswer.mission_file);
            this.userAnswer.mission_file ? formData.append("answer_file", this.userAnswer.mission_file)
                : formData.append("answer_text", this.userAnswer.answer_text);
            this.$emit("onSubmit", {
                userAnswer: formData
            }, function (data) {
                if (!data.status) {
                    if (data.code !== 422) {
                        this.errorMessages.push(this.$t(data.error.message));
                        return true;
                    }
                    this.showErrors(data.error.errors);
                } else {
                    // this.successMessage = self.$t('survey.saved_successfully')

                    setTimeout(() => {
                        self.closeModal();
                    }, 1000)
                }
            }
            );
            // }

        },
        showErrors(errors) {
            this.resetMessages();
            for (const [field, error] of Object.entries(errors)) {
                if (Array.isArray(error)) {
                    error.forEach((text) => {
                        this.errorMessages.push(text);
                    });
                } else this.errorMessages.push(error);
            }
        },
        closeModal() {
            this.$emit('onClose', true);
            if (this.modal_name) this.$modal.hide(this.modal_name);
        },
        resetMessages() {
            this.errorMessages = [];
        },
    },
    watch: {
        errorMessages() {
            this.debouncedResetValues();
        },
    },
    mounted() {
        this.answer_type = this.mission.answer_type
    },
    created: function () {
        this.debouncedResetValues = _.debounce(this.resetMessages, 2500);
    },
};
</script>
<style scoped>
.line-height-1 {
    line-height: 1rem;
}
</style>