<template>
  <div :class="modal_name ? 'p-4' : 'px-4 pt-5 pb-4 modal-dialog'" style="background-color:transparent !important;">
    <button v-if="!modal_name" class="uk-modal-close-default" type="button" uk-close @click="closeModal"></button>
    <div class="section-header border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span> {{ modal_title || formattedSurvey.name }} </span>
        </h2>
      </div>
    </div>
    <div v-if="errorMessages.length" class="uk-alert-danger" uk-alert>
      <template v-for="(error, index) in errorMessages">
        <span :key="index" v-if="error != null">
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red" class="m-0">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </span>
      </template>
    </div>
    <div v-if="successMessage" class="uk-alert-success" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: green">
        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
      </p>
    </div>
    <!-- Card header -->
    <div class="card-body actions-toolbar border-0">
      <template v-for="(question, questionIndex) in questions">
        <div v-if="question !== null && question.type === 1" class="form-group row mb-3"
          :key="'new_question_box_' + questionIndex">
          <h6 class="col-12">{{ questionIndex + 1 + ". " + question.name }}</h6>
          <div class="col-12 d-flex justify-content-between align-content-center flex-column">
            <div class="form-check-inline" v-for="option in question.options" :key="option.point">
              <label class="form-check-label d-flex mb-1">
                <input type="radio" class="form-check-input" @click="clickAnswer(option.point, questionIndex)"
                  :value="question.answerPoint" :name="'option' + questionIndex + '_radio_group'" />
                {{ option.label }}
              </label>
            </div>
          </div>
        </div>

        <div v-if="question !== null && question.type === 2" class="form-group row mb-3"
          :key="'new_question_box_' + questionIndex">
          <h6 class="col-12">{{ question.name }}</h6>
          <div class="col-12">
            <textarea v-model="question.answerPoint"></textarea>
          </div>
        </div>

        <!--        <div-->
        <!--          v-if="question.question_type_id == 2"-->
        <!--          class="form-group row mb-3"-->
        <!--          :key="'question_box_' + index"-->
        <!--        >-->
        <!--          <h6 class="col-12">{{ index + 1 + ". " + question.text }}</h6>-->
        <!--          <div class="col-12">-->
        <!--            <span-->
        <!--              v-for="(answer, answer_index) in question.question_answers"-->
        <!--              class="d-inline-flex align-items-center"-->
        <!--              :class="answer_index != 0 ? ' ml-2' : ''"-->
        <!--              :key="'question_' + index + '_answer_box_' + answer_index"-->
        <!--            >-->
        <!--              <input-->
        <!--                type="checkbox"-->
        <!--                class="d-inline-flex mb-0 mr-1 line-height-1"-->
        <!--                v-model="answers[question.id]"-->
        <!--                :value="answer.id"-->
        <!--                :id="'question_' + index + '_answer_' + answer_index"-->
        <!--              />-->
        <!--              <label-->
        <!--                class="small m-0 cursor-pointer"-->
        <!--                :for="'question_' + index + '_answer_' + answer_index"-->
        <!--              >-->
        <!--                {{ answer.text }}</label-->
        <!--              >-->
        <!--            </span>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div-->
        <!--          v-if="question.question_type_id == 1"-->
        <!--          class="form-group row mb-3"-->
        <!--          :key="'question_box_' + index"-->
        <!--        >-->
        <!--          <h6 class="col-12">{{ index + 1 + ". " + question.text }}</h6>-->
        <!--          <div class="col-12">-->
        <!--            <span-->
        <!--              v-for="(answer, answer_index) in question.question_answers"-->
        <!--              class="d-inline-flex align-items-center"-->
        <!--              :class="answer_index != 0 ? ' ml-2' : ''"-->
        <!--              :key="'question_' + index + '_answer_box_' + answer_index"-->
        <!--            >-->
        <!--              <input-->
        <!--                type="radio"-->
        <!--                class="d-inline-flex mb-0 mr-1 line-height-1"-->
        <!--                v-model="answers[question.id]"-->
        <!--                :name="'question_' + index + '_radio_group'"-->
        <!--                :value="answer.id"-->
        <!--                :id="'question_' + index + '_answer_' + answer_index"-->
        <!--              />-->
        <!--              <label-->
        <!--                class="small m-0 cursor-pointer"-->
        <!--                :for="'question_' + index + '_answer_' + answer_index"-->
        <!--              >-->
        <!--                {{ answer.text }}</label-->
        <!--              >-->
        <!--            </span>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div-->
        <!--          v-if="question.question_type_id == 6"-->
        <!--          class="form-group row mb-3"-->
        <!--          :key="'question_box_' + index"-->
        <!--        >-->
        <!--          <h6 class="col-12">{{ index + 1 + ". " + question.text }}</h6>-->
        <!--          <div class="col-12">-->
        <!--            <textarea-->
        <!--              v-model="answers[question.id]"-->
        <!--              :placeholder="question.question_answers[0].text"-->
        <!--              :id="'question_' + index + '_answer_' + 0"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->
      </template>
    </div>
    <div class="card-footer d-flex justify-content-center p-4">
      <button v-if="modal_name" @click="closeModal" class="btn btn-sm btn-secondary button btn-pill cursor-pointer mr-2">
        {{ $t("general.close") }}
      </button>

      <button @click="onSubmit" class="btn btn-sm btn-primary button btn-pill cursor-pointer" v-if="!hideSubmit">
        {{ $t("general.submit") }}
      </button>
    </div>
  </div>
</template>

<script>
import Template from "../../company/Mail/Template.vue";

export default {
  components: { Template },
  name: "EvaluationQuestionList",
  props: {
    survey: {
      required: true,
      default: [],
    },
    modal_name: {
      required: true,
    },
    modal_title: {
      required: false,
    },
    hideSubmit: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      errorMessages: [],
      successMessage: null,
      questions: [],
    };
  },
  // computed: {
  //   formattedSurvey() {
  //
  //     if (!this.survey.name) return [];
  //
  //     let questions = [];
  //
  //     this.survey.questions.forEach((question) => {
  //
  //
  //
  //       let selfQuestion = Object.assign({}, question);
  //       let options = {};
  //       switch (selfQuestion.type) {
  //         case 1:
  //           options = JSON.parse(selfQuestion.options);
  //           break;
  //         case 2:
  //           options = {};
  //           break;
  //       }
  //
  //
  //       let formattedQuestion = {
  //         id: selfQuestion.id,
  //         name: selfQuestion.name,
  //         type: selfQuestion.type,
  //         options: options
  //       };
  //
  //       //this.survey.questions[index] = formattedQuestion;
  //       questions[selfQuestion.id] = formattedQuestion;
  //
  //
  //       // if (element.question_type_id == 2) this.answers[element.id] = [];
  //       // else this.answers[element.id] = null;
  //       // this.question_indexes[element.id] = index;
  //     });
  //
  //
  //     //this.survey.questions = questions;
  //     this.questions = questions;
  //     return this.survey;
  //   },
  // },
  methods: {
    clickAnswer(point, questionIndex) {
      this.questions[questionIndex].answerPoint = point;
    },
    makeQuestions() {
      if (!this.survey.name) return [];

      let questions = [];

      this.survey.questions.forEach((question, index) => {
        let selfQuestion = Object.assign({}, question);

        let options = {};
        let answerPoint = null;
        switch (selfQuestion.type) {
          case 1:
            options = JSON.parse(selfQuestion.options);
            answerPoint = 1;
            break;
          case 2:
            options = {};
            answerPoint = null;
            break;
        }

        questions[index] = {
          id: selfQuestion.id,
          name: selfQuestion.name,
          type: selfQuestion.type,
          answerPoint: answerPoint,
          options: options,
        };
      });

      // questions[0] = null;
      // questions.splice(0, 1);
      this.questions = questions;
    },
    onSubmit() {
      let self = this;
      self.resetMessages();
      let validated = true;
      for (const [questionIndex, question] of Object.entries(self.questions)) {
        if (question.type === 1 && question.answerPoint == null) {
          self.errorMessages.push(
            self
              .$t("question.question_required")
              .replace(":NAME", question.name)
          );
          validated = false;
        }
      }
      if (validated) {
        self.$emit(
          "onSubmit",
          {
            //survey_id: self.survey.id,
            answers: self.questions,
          },
          function (data) {
            if (!data.status) {
              if (data.code !== 422) {
                self.errorMessages.push(self.$t("errors." + data.code));
                return true;
              }
              self.showErrors(data.error.errors);
            }
          }
        );
      }

      // for (const [question_id, answer] of Object.entries(self.answers)) {
      //   if (answer == null || answer.length == 0 || (typeof answer == 'string' && answer.length < 3)) {
      //     self.errorMessages[self.question_indexes[question_id]] = self
      //       .$t("question.question_required")
      //       .replace(":NAME", self.question_indexes[question_id] + 1);
      //   }
      // }
      // if (self.errorMessages.length == 0) {
      //   self.$emit(
      //     "onSubmit",
      //     {
      //       answers: self.answers,
      //     },
      //     function (data) {
      //       if (!data.status) {
      //         if (data.code != 422) {
      //           self.errorMessages.push(self.$t("errors." + data.code));
      //           return true;
      //         }
      //         self.showErrors(data.error.errors);
      //       }
      //     }
      //   );
      // }
    },
    showErrors(errors) {
      this.resetMessages();
      for (const [field, error] of Object.entries(errors)) {
        if (Array.isArray(error)) {
          error.forEach((text) => {
            this.errorMessages.push(text);
          });
        } else this.errorMessages.push(error);
      }
    },
    closeModal() {
      if (this.modal_name) this.$modal.hide(this.modal_name);
    },
    resetMessages() {
      this.errorMessages = [];
    },
  },
  watch: {
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  mounted() {
    //this.questions = this.formattedSurvey;
    this.makeQuestions();
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2500);
  },
};
</script>
<style scoped>
.line-height-1 {
  line-height: 1rem;
}

</style>
