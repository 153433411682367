<template>
    <div :class="modal_name ? 'p-4' : 'px-4 pt-5 pb-4'">
        <button v-if="!modal_name" class="uk-modal-close-default" type="button" uk-close @click="closeModal"></button>

        <div class="container">
            <div class="col-md-8 offset-md-2">
                <div class="section-header border-0 uk-flex-middle">
                    <div class="section-header-left d-flex align-items-center">
                        <img src="@/assets/images/img-checklist.png" />
                        <p class="lead text-left ml-3">{{ modal_title }}</p>
                    </div>
                </div>
                <div v-if="errorMessages.length" class="uk-alert-danger" uk-alert>
                    <template v-for="(error, index) in errorMessages">
                        <span :key="index" v-if="error != null">
                            <a class="uk-alert-close" uk-close></a>
                            <p style="color: red" class="m-0">
                                <i class="uil-exclamation-triangle"></i> {{ error }}
                            </p>
                        </span>
                    </template>
                </div>
                <div v-if="successMessage" class="uk-alert-success" uk-alert>
                    <a class="uk-alert-close" uk-close></a>
                    <p style="color: green">
                        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
                    </p>
                </div>

                <!-- Card header -->
                <div class="card-body actions-toolbar border-0" style="max-height: 50vh;overflow-y: auto">
                    <ul class="list-group">
                        <template v-for="(checkListItem, index) in checkList">
                            <li class="list-group-item d-flex"><input
                                    @click="userChangeCheckItem(checkListItem.id, $event, index)" type="checkbox"
                                    name="checkbox" class="w-auto mr-2 m-0 cursor-pointer" :id="`checkbox-${index}`"
                                    value="value" /><label :for="`checkbox-${index}`"
                                    class="d-inline m-0 cursor-pointer">&nbsp;&nbsp;{{ checkListItem.name }}
                                    <span class="required" v-if="checkListItem.is_mandatory">*</span>
                                </label></li>
                        </template>
                    </ul>
                </div>
                <div class="card-footer d-flex justify-content-between p-2">
                    <p class="mt-2 text-center"><i>Yıldızlı (*) maddeleri işaretlemek zorunludur.</i></p>
                    <div>
                        <button v-if="modal_name" @click="closeModal"
                            class="btn btn-sm btn-secondary button btn-pill cursor-pointer mr-2">
                            {{ $t("general.close") }}
                        </button>
                        <button @click="onSubmit" class="btn btn-sm btn-primary button btn-pill cursor-pointer"
                            v-if="!hideSubmit">
                            <i class="uil-check"></i> {{ $t("general.submit") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import Template from "../../company/Mail/Template.vue";

export default {
    components: { Template },
    name: "CheckList",
    props: {
        checkList: {
            required: true,
            default: [],
        },
        modal_name: {
            required: true,
        },
        modal_title: {
            required: false,
        }
    },
    data() {
        return {
            errorMessages: [],
            successMessage: null,
            userCheckListAnswer: [],
            hideSubmit: false
        };
    },
    computed: {
    },
    methods: {
        userChangeCheckItem(checkListItem, event, index) {
            this.userCheckListAnswer[index]['value'] = event.target.checked
            this.hideSubmit = false;
            this.checkList.every(data => {
                if (data.is_mandatory) {
                    this.userCheckListAnswer.every(answer => {
                        if (data.id === answer.id) {
                            if (answer.value) {
                                this.hideSubmit = false
                            } else {
                                this.hideSubmit = true
                            }
                            return false;
                        } else {
                            return true;
                        }
                    })
                }
                if (this.hideSubmit) {
                    return false
                } else {
                    return true
                }
            })
        },
        onSubmit() {
            let self = this;
            this.$emit("onSubmit", {
                content_check_list_answers: this.userCheckListAnswer,
            }, function (data) {
                if (!data.status) {
                    if (data.code !== 422) {
                        this.errorMessages.push(this.$t(data.error.message));
                        return true;
                    }
                    this.showErrors(data.error.errors);
                } else {
                    // this.successMessage = self.$t('survey.saved_successfully')

                    setTimeout(() => {
                        self.closeModal();
                    }, 1000)
                }
            }
            );
            // }

        },
        showErrors(errors) {
            this.resetMessages();
            for (const [field, error] of Object.entries(errors)) {
                if (Array.isArray(error)) {
                    error.forEach((text) => {
                        this.errorMessages.push(text);
                    });
                } else this.errorMessages.push(error);
            }
        },
        closeModal() {
            this.$emit('onClose', true);
            if (this.modal_name) this.$modal.hide(this.modal_name);
        },
        resetMessages() {
            this.errorMessages = [];
        },
    },
    watch: {
        errorMessages() {
            this.debouncedResetValues();
        },
    },
    mounted() {
        for (let i = 0; i < this.checkList.length; i++) {
            this.userCheckListAnswer.push({
                id: this.checkList[i].id, value: false
            })
        }
        this.checkList.forEach(data => {
            if (data.is_mandatory)
                this.hideSubmit = true
        })
        //this.questions = this.formattedSurvey;
        // this.makeQuestions();
    },
    created: function () {
        this.debouncedResetValues = _.debounce(this.resetMessages, 2500);
    },
};
</script>
<style scoped>
.line-height-1 {
    line-height: 1rem;
}
</style>
  
  