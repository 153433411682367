<template>
  <div class="p-5 d-flex flex-column align-items-start">
    <button
      class="uk-modal-close-default"
      type="button"
      uk-close
      @click="closeModal"
    ></button>
    <div
      v-if="errorMessages.length"
      class="uk-alert-danger position-relative w-100 p-2 mb-3"
      uk-alert
    >
      <template v-for="(error, index) in errorMessages">
        <span :key="index" v-if="error != null">
          <a class="uk-alert-close" uk-close style="top: 10px; right: 10px"></a>
          <p style="color: red" class="m-0">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </span>
      </template>
    </div>
    <h3 class="mb-0">{{ title ? title : $t("review.give_feedback") }}</h3>
    <p class="font-weight-bold">{{ sub_title }}</p>
    <star-rating
      class="align-self-center mt-4 mb-4"
      :rating="rating"
      @rating-selected="setRating"
      :animate="animate"
      :increment="increment"
      :inactive-color="inactive_color"
      :show-rating="show_rating"
      :max-rating="max_rating"
      :star-size="star_size"
      :padding="padding"
      :border-width="border_width"
      :star-points="start_points"
    ></star-rating>
    <p class="mb-2 font-weight-bold">
      {{ review_text ? review_text : $t("review.review_text") }}
    </p>
    <textarea class="form-control" rows="5" v-model="comment"></textarea>
    <div class="d-flex justify-content-center w-100 mt-3">
      <button
        @click="onSubmit"
        :disabled="!comment.length"
        class="btn btn-sm btn-primary btn-pill cursor-pointer"
      >
        {{ $t("general.submit") }}
      </button>
    </div>
  </div>
</template>

<script>
import Template from "../../company/Mail/Template.vue";
import StarRating from "vue-star-rating";
export default {
  components: { Template, StarRating },
  name: "Rating",
  props: {
    rating: {
      required: false,
      default: 10,
    },
    increment: {
      required: false,
      default: 0.1,
    },
    padding: {
      required: false,
      default: 5,
    },
    border_width: {
      required: false,
      default: 3,
    },
    show_rating: {
      required: false,
      default: false,
    },
    star_size: {
      required: false,
      default: 25,
    },
    animate: {
      required: false,
      default: true,
    },
    inactive_color: {
      required: false,
      default: "#fff",
    },
    max_rating: {
      required: false,
      default: 10,
    },
    title: {
      required: false,
      default: "",
    },
    sub_title: {
      required: false,
      default: "",
    },
    review_text: {
      required: false,
      default: "",
    },
    start_points: {
      required: false,
      default: () => {
        return [
          23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19,
          31, 17,
        ];
      },
    },
    modal_name: {
      required: true,
      default: "",
    },
  },
  data() {
    return {
      selected_rating: this.rating,
      comment_error: false,
      comment: "",
      errorMessages: [],
    };
  },
  methods: {
    setRating: function (rating) {
      this.selected_rating = rating;
    },
    onSubmit() {
      let self = this;
      if(self.comment.length == 0)return false;
      self.$emit(
        "onSubmit",
        {
          rating: self.selected_rating,
          comment: self.comment,
        },
        function (data) {
          if(Array.isArray(data)){
            data.forEach(element => {
              self.errorMessages.push(element);
            });
          }
          else self.errorMessages.push(data);
        }
      );
    },
    closeModal() {
      if (this.modal_name) this.$modal.hide(this.modal_name);
    },
    resetMessages() {
      this.errorMessages = [];
    },
  },
  watch: {
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2500);
  },
};
</script>
<style scoped>
.line-height-1 {
  line-height: 1rem;
}
</style>

